.picture {
  position: absolute;
  will-change: left, top, width, height, transform, filter, opacify;
  transition: all 3.0s cubic-bezier(0.15, 0.75, 0.05, 1.00);

  .parallax {
    display: flex;
    justify-content: center;
  }

  .imageWrapper {
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 4px 0 16px #333;

    .image {
      user-select: none;
      object-fit: cover;
      border-radius: 7px;
      background-color: #eee;
      will-change: width, height;
      transition: all 3.0s cubic-bezier(0.15, 0.75, 0.05, 1.00);
    }

    .label {
      display: block;
      font-size: 12px;
      color: #666;
      transform-origin: left;
      transform: scale(0.7);
      margin-left: 4px;
    }
  }
}
