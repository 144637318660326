@import "../../style/global";

.gallery {
  flex-grow: 1;
  position: relative;
}

.tab {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000000000;
}
