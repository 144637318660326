$accentColor: #0FA1FF;
$accentColorLighter: #49acff;
$accentColorDarken: #098fff;

$primaryColor: #ffc100;
$primaryColorLighter: #ffd35d;
$primaryColorDarken: #bb8c00;

$secondaryColor: #ff4747;
$secondaryColorLighter: #ff7b7b;
$secondaryColorDarken: #a33434;

$darkColor: #1c1c1c;
